
@mixin res_font($min, $viewport, $max) {
  font-size: unquote('clamp(#{$min}, #{$viewport} ,#{$max})');
}

@mixin res_width($min, $viewport, $max) {
  width: unquote('clamp(#{$min}, #{$viewport} ,#{$max})');
}

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-down {
  @media (max-width: 1023px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 1024px) { @content; }
}
@mixin for-desktop-down {
  @media (max-width: 1199px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}

@mixin for-small-desktop-down {
  @media (max-width: 1440px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}