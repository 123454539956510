@import 'styles/index.scss';

.pageContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  .particlesWrapper {
    width: 100%;
    min-height: 100vh;
    position: absolute;
  }
}
