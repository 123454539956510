@import 'styles/index.scss';


.projectContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $lightBlue;
  box-shadow: 0 0 20px 0 $pink;
  min-width: 300px;
  min-height: 25vh;
  margin: 2rem;
  border-radius: 10px;


  .projectImgContainer {
    width: 200px;
    height: auto;

    .projectImg {
      object-fit: contain;
      height: inherit;
      width: inherit;
    }
  }

  .cardLink {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}