@import 'styles/index.scss';

.projectsWrapper {
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  .projectsContainer {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  
    .projectWrapper {
      margin: 1rem;
    }
  }

  .paddingTop {
    padding-top: 4rem;
  }
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}