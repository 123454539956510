@import 'styles/Colors';
$hexagon-size: 100px;
$hexagon-color-1: #00ffb8;
$hexagon-color-2: #13ce9a;

.hexagonWrapper {
  position: relative;
  .hexagon {
    width: ($hexagon-size * 0.645);
    height: $hexagon-size;
    background-color: $pink;
    border-radius: ($hexagon-size / 10);
    transition: transform 400ms ease;
    
    .hexagonContent {
      display: flex;
      justify-content: center;
      align-items: center;
      width: inherit;
      height: inherit;
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .hexagon:before,
  .hexagon:after {
    content: '';
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: inherit;
    height: inherit;
    background-color: inherit;
    border-radius: inherit;
    z-index: 1;
  }
  
  .hexagon:before {
    transform: rotate(60deg);
    z-index: 1;
  }
  
  .hexagon:after {
    transform: rotate(-60deg);
    z-index: 1;
  }
}

