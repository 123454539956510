@import 'styles/index.scss';


.routerContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $darkBlue;
  .particlesWrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 0;
  }
  .pageContainer {
    min-height: 100vh;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
}
