
.pageContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 10rem;
  width: 100%;
  padding-top: 5rem;
  .toolsContainer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    .toolWrapper {
      margin: 0 2rem;
      flex: 1;
      .iconSize {
        width: 40px;
        height: 40px;
      }
    }
  }
}
