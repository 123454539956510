@import 'styles/index.scss';


.expandedProjectContainer {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay {
    z-index: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    will-change: opacity;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    .overlayLink {
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .linkWrapper {
    text-decoration: none;
    color: $white;

    .expandedProject {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include res_width(200px, 90vw, 800px);
      background-color: $lightBlue;
      box-shadow: 0 0px 20px 0px $pink;
      border-radius: 10px;
      overflow: hidden;

      .projectImgContainer {
        @include res_width(200px, 70vw, 300px);
        height: 300px;

        .projectImg {
          object-fit: contain;
          height: inherit;
          width: inherit;
        }
      }

      .projectInfo {
        width: 100%;
        overflow: hidden;
        background-color: $darkBlue;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .projectTitle {
          @include res_font(2rem, 3vw, 2.5rem);
          font-family: 'Raleway';
          margin: 1rem 0;
        }

        .projectDescription {
          @include res_font(1rem, 3vw, 1.3rem);
          font-family: 'Raleway';
          margin: 0 1rem 1rem 1rem;
        }

        .subtitle {
          @include res_font(1.2rem, 3vw, 1.7rem);
          font-family: 'Raleway';
          margin: 1rem 0;
          text-align: left;
          width: 80%;
          margin-bottom: 0;
        }

        .projectTime {
          @include res_font(0.8rem, 3vw, 1.2rem);
          font-family: 'Raleway';
          padding: 0.5rem;
          text-align: left;
          width: 80%;
        }

        .projectToolsContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          width: 80%;
          padding: 1rem 0 2rem 0;

          .projectTool {
            @include res_font(0.8rem, 3vw, 1.2rem);
            font-family: 'Raleway';
            padding: 0.5rem;
            flex: 1 33%;
            text-align: left;
            width: 100px;
          }
        }
      }
    }
  }
}