@import 'styles/index.scss';


.hexagonButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  color: $white;
  max-width: 100px;

  .hexagon1 {
    animation: normalColor ease 1s;
    background-color: $pink;

  }

  .onHoverHexagon {
    animation: hoverColor ease 1s;
    background-color: $purple;
  }

  .textContainer {
    margin: 1rem 0;
    opacity: 0;
    text-align: center;
    animation: fadeOut ease 1s;
    @include res_font(1rem, 3vw, 1.2rem);
    font-family: 'Raleway';
  }

  .onHoverText {
    animation: fadeIn ease 0.4s;
    opacity: 1;
  }

  @keyframes hoverColor {
    0% {
      background-color:$pink;

    }
    100% {
      background-color:$purple;

    }
  }

  @keyframes normalColor {
    0% {background-color:$purple;}
    100% {background-color:$pink;}
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

    @keyframes fadeOut {
      0% {opacity:1;}
      100% {opacity:0;}
    }
}