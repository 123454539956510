@import 'styles/Colors';

.icon {
  font-size: 25px;
  color: $darkGray;
  line-height: 50px;
  transition: 0.5s;
}

.iconHover {
  color: $pink;
}

.SocialMediaButton {
  margin: 0 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: $white;
  text-align: center;
  transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(0,0);
  transition: 0.5s;
  box-shadow: -20px 20px 10px $pink;
}

.SocialMediaButton::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -10px;
  height: 100%;
  width: 10px;
  background: $lightGray;
  transition: 0.5s;
  transform: rotate(0deg) skewY(-45deg);
}

.SocialMediaButton::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -5px;
  height: 10px;
  width: 100%;
  background: $lightGray;
  transition: 0.5s;
  transform: rotate(0deg) skewX(-45deg);
}

.SocialMediaButton:hover {
  transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(20px,-20px);
  box-shadow: -50px 50px 50px $pink;

}
