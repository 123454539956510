@import 'styles/index.scss';

.flipImageContainer {
  display: flex;
  background-color: transparent;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  overflow: hidden;
  perspective: 1000px;


  .flipImageInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.4s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

    .flipImageFront, .flipImageBack {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .flipImageBack {
      transform: rotateY(180deg);
    }

    .flipImage {
      width: inherit;
      object-fit: contain;
    }

  }
}
.flipImg {
  transform: rotateY(180deg);
}
