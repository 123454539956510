@import 'styles/index.scss';


.aboutMeConatiner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: none;

  .infoContainer {
    display: flex;
    flex-direction: column;
    color: $white;
    @include for-tablet-portrait-up {
      max-width: 70%;
      margin-left: 2rem;
    }

    .infoTitle {
      margin: 0;
      font-family: 'Raleway';

      @include res_font(0.5rem, 10vw, 3rem);
      text-align: left;
      @include for-phone-only {
        text-align: center;
      }
    }

    .infoText {
      margin: 0.5rem 0;
      @include res_font(1rem, 3vw, 3rem);
      text-align: left;
      @include for-phone-only {
        text-align: center;
      }
    }

    .glowingText {
      background: linear-gradient(45deg, #eee, $pink);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .italicText {
      font-style: italic;
    }
  }
}
